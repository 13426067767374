import routes from "@/constants/routes";
import getConfig from "next/config";
import { LandingNavLink, GetNavItems } from "./types";

const { publicRuntimeConfig } = getConfig();

const blogLink =
  publicRuntimeConfig?.NEXT_BLOG_PAGE === "1" ? routes.blog : "https://medium.com/@keyzy";

export const getNavItems = ({ withLogin, route, isMobile }: GetNavItems): LandingNavLink[] => {
  const landingDesktopLinks: LandingNavLink[] = [
    { caption: "How it works", href: routes.howitworks },
    { caption: "Success Stories", href: routes.customerReviews },
    {
      caption: "Budget calculator",
      href: routes.budgetCalculator,
    },
    {
      caption: "About",
      items: [
        { caption: "FAQs", href: routes.faqs },
        { caption: "Our story", href: routes.about },
        { caption: "Key workers", href: routes.keyWorkers },
        {
          caption: "Blog",
          href: blogLink,
        },
        { caption: "Careers", href: "mailto:careers@keyzy.com" },
        { caption: "Contact us", href: routes.contact },
      ],
      href: "",
    },
    {
      caption: "Partners",
      items: [
        { caption: "Intermediaries", href: routes.intermediaries },
        { caption: "Landlords", href: routes.landlords.root },
        { caption: "Portal login", href: "https://intermediaries.keyzy.com/" },
      ],
      href: "",
    },
    // {
    //   caption: "Intermediaries",
    //   href: routes.intermediaries,
    // },
    // { caption: "FAQs", href: routes.faqs },
    // { caption: "About us", href: routes.about },
  ];

  const landingMobileLinks: LandingNavLink[] = [
    { caption: "Home", href: routes.home },
    ...landingDesktopLinks,
    // { caption: "Contact us", href: routes.contact },
  ];

  const intermediariesDesktopLinks: LandingNavLink[] = [
    { caption: "How it works", href: routes.howitworks },
    { caption: "Success Stories", href: routes.customerReviews },
    {
      caption: "Budget calculator",
      href: routes.budgetCalculator,
    },
    {
      caption: "About",
      items: [
        { caption: "FAQs", href: routes.faqs },
        { caption: "Our story", href: routes.about },
        { caption: "Key workers", href: routes.keyWorkers },
        {
          caption: "Blog",
          href: blogLink,
        },
        { caption: "Careers", href: "mailto:careers@keyzy.com" },
        { caption: "Contact us", href: routes.contact },
      ],
      href: "",
    },
    {
      caption: "Partners",
      items: [
        { caption: "Intermediaries", href: routes.intermediaries },
        { caption: "Landlords", href: routes.landlords.root },
        { caption: "Portal login", href: "https://intermediaries.keyzy.com/" },
      ],
      href: "",
    },
    { caption: "Login", href: `${publicRuntimeConfig?.BROKER_PORTAL_URL}/login` },
  ];

  const intermediariesMobileLinks: LandingNavLink[] = [
    { caption: "Home", href: routes.home },
    { caption: "How it works", href: routes.howitworks },
    {
      caption: "Budget calculator",
      href: routes.budgetCalculator,
    },
    {
      caption: "About",
      items: [
        { caption: "FAQs", href: routes.faqs },
        { caption: "Our story", href: routes.faqs },
        { caption: "Key workers", href: routes.keyWorkers },
        {
          caption: "Blog",
          href: blogLink,
        },
        { caption: "Careers", href: "mailto:careers@keyzy.com" },
        { caption: "Contact us", href: routes.contact },
      ],
      href: "",
    },
    {
      caption: "Partners",
      items: [
        { caption: "Intermediaries", href: routes.intermediaries },
        { caption: "Landlords", href: routes.landlords.root },
        { caption: "Portal login", href: "https://intermediaries.keyzy.com/" },
      ],
      href: "",
    },
    { caption: "Login", href: `${publicRuntimeConfig?.BROKER_PORTAL_URL}/login` },
  ];

  const landlordDesktopLinks: LandingNavLink[] = [
    { caption: "Keyzy for Landlords", href: routes.landlords.root },
    { caption: "How it works", href: routes.landlords.howItWorks },
    { caption: "About us", href: routes.landlords.aboutUs },
    { caption: "Deal calculator", href: routes.landlords.dealCalculator },
  ];

  const landlordMobileLinks: LandingNavLink[] = [
    { caption: "Keyzy for Landlords", href: routes.landlords.root },
    { caption: "How it works", href: routes.landlords.howItWorks },
    { caption: "About us", href: routes.landlords.aboutUs },
    { caption: "Deal calculator", href: routes.landlords.dealCalculator },
  ];

  const landlordRedirectDesktopLinks: LandingNavLink[] = [
    { caption: "Keyzy for Landlords", href: routes.landlords.root },
    { caption: "Deal calculator", href: routes.landlords.dealCalculator },
  ];

  const landlordRedirectMobileLinks: LandingNavLink[] = [
    { caption: "Keyzy for Landlords", href: routes.landlords.root },
    { caption: "Deal calculator", href: routes.landlords.dealCalculator },
  ];

  const keyzyNowDesktopLinks: LandingNavLink[] = [
    { caption: "Keyzy Now", href: routes.now.root },
    { caption: "How it works", href: routes.now.howItWorks },
    { caption: "Search homes", href: routes.now.searchHomes },
    { caption: "FAQs", href: routes.now.faqs },
  ];

  const keyzyNowMobileLinks: LandingNavLink[] = [
    { caption: "Keyzy Now", href: routes.now.root },
    { caption: "How it works", href: routes.now.howItWorks },
    { caption: "Search homes", href: routes.now.searchHomes },
    { caption: "FAQs", href: routes.now.faqs },
  ];

  [
    landingDesktopLinks,
    landingMobileLinks,
    intermediariesDesktopLinks,
    intermediariesMobileLinks,
    landlordDesktopLinks,
    landlordMobileLinks,
  ].forEach((linksArray) => {
    if (withLogin && !isMobile) {
      linksArray.push({ caption: "Login", href: routes.dashboard.login });
    }
  });

  if (route === "intermediary") {
    return isMobile ? intermediariesMobileLinks : intermediariesDesktopLinks;
  } else if (route === "landlords") {
    return isMobile ? landlordMobileLinks : landlordDesktopLinks;
  } else if (route === "landlordsRedirect") {
    return isMobile ? landlordRedirectMobileLinks : landlordRedirectDesktopLinks;
  } else if (route === "now") {
    return isMobile ? keyzyNowMobileLinks : keyzyNowDesktopLinks;
  } else {
    return isMobile ? landingMobileLinks : landingDesktopLinks;
  }
};
